export const LEGAL_TERMS_NAVIGATION_DATA = [
    {
        id: 'top',
        icon: './image/icon/navigation_icon.svg',
        title: 'Back to top'
    },
    {
        id: 'identification',
        icon: './image/icon/navigation_icon.svg',
        title: 'Identification'
    },
    {
        id: 'general',
        icon: './image/icon/navigation_icon.svg',
        title: 'General conditions'
    },
    {
        id: 'privacy',
        icon: './image/icon/navigation_icon.svg',
        title: 'Privacy'
    },
    {
        id: 'cookies',
        icon: './image/icon/navigation_icon.svg',
        title: 'Use of cookies'
    },
    {
        id: 'preservation',
        icon: './image/icon/navigation_icon.svg',
        title: 'Preservation of rights'
    },
    {
        id: 'miscellaneous',
        icon: './image/icon/navigation_icon.svg',
        title: 'Miscellaneous'
    },
]
export const LEGAL_TERMS_DATA = {
    identification: {
        title: 'I. Identification of AGC GLASS EUROPE SA',
        content: [
            {
                text: 'This is the Website of AGC GLASS EUROPE SA, a company under Belgian law (hereinafter referred to as « AGC »), registered for VAT under the number BE 413.638.187 and with the Crossroads Bank for Enterprises under number 0413.638.187.'
            },
            {
                text: `Our postal address is: Avenue Jean Monnet 4, 1348 Louvain-la-Neuve, Belgium, telephone: +32 (0)2 409 30 00, email: communications@eu.agc.com`
            }
        ]
    },

    generalConditions: {
        title: 'II. General conditions for use of this website',
        content: {
            subtitle: 'Access to and use of the AGC Website [www.reeflect.  com] (the « Website ») are subject to the conditions set out below and the applicable laws.',
            accessibility: {
                title: '1. Accessibility of the Website',
                content: [
                    {
                        text: 'AGC strives as far as possible to ensure that services are accessible 7 days a week and 24 hours a day.'
                    },
                    {
                        text: 'However, AGC may interrupt access, notably for reasons of maintenance and upgrading, or for any other reasons, specifically technical reasons.'
                    }
                ]
            },
            limitation: {
                title: '2. Limitation of liability',
                content: [
                    {
                        text: 'AGC is under no circumstances liable for the interruptions mentioned under point 1 or the consequences which may arise from them for the user or any third party.'
                    },
                    {
                        text: 'AGC offers no guarantee as to the accuracy or up-to-date status of the information presented on this site.'
                    },
                    {
                        text: 'AGC is not liable for any damage or any temporary or permanent incident occurring to the user’s data or computer hardware when accessing the site, when working through its pages or in general during the transmission of the computer programs and files which compose the site on his receiving hardware. In particular, AGC is not liable for the potential transmission of any viruses via its site.'
                    },
                    {
                        text: 'AGC is not liable for any damage which may arise from unauthorised intrusion into its computer systems and/or fraudulent use of its transmission mechanisms. It accepts no liability, notably in the event of any theft of data following an intrusion into its computer systems. However, AGC does use the appropriate methods to prevent illegal intrusions.'
                    }
                ]
            },
            intelectualProperty: {
                title: '3. Intellectual property',
                content: [
                    {
                        text: 'The structure, the layout and all the elements contained in this site are protected, in favour of AGC and/or any other company of its group and/or its potential suppliers or partners, notably by the laws on (i) copyright and associated rights, (ii) the protection of computer programs, and (iii) databases. Users undertake not to breach the rights of AGC, any other company of its group and/or its potential suppliers or partners.'
                    },
                    {
                        text: 'Other names of products or companies mentioned on this site may constitute the brands of their respective owners. Users are not authorised to modify, copy, distribute, transmit, disseminate, represent, reproduce, publish, award under licence, transfer or sell any information, software, product or service obtained from this site, or to create works derived from the elements mentioned above, without the prior written consent of AGC.'
                    },
                ],
            },
            thirdPartyWebsites: {
                title: '4. Links to third-party Websites',
                content: [
                    {
                        text: 'Web spaces outside the domain of this site and notably the spaces to which users may be directed by means of hypertext links within the pages of this Website remain the sole liability of the holders of those web spaces.'
                    },
                    {
                        text: 'The point is that AGC cannot exercise any permanent control over those sites, or bear any liability as to their content. The insertion of such links does not mean that AGC approves the elements contained on those sites.'
                    },
                    {
                        text: 'AGC thus takes no liability, notably with regard to the material, to the data of any kind whatsoever contained or disseminated on those web spaces to which users may be referred, or to any processing of personal data that may be conducted through them.'
                    }
                ]
            }
        }
    },

    privacy: {
        title: 'III. Privacy',
        content: {
            aboutPolicy: {
                title: '1. About this Policy',
                content: [
                    {
                        text: '1.1 This Privacy Policy (« Policy ») explains how we (as defined below) collect, share and use any information that, alone or in combination with other information, relates to you (« Personal Data ») when you (« you » and « your ») use our website [www.reeflect.com] (the « Website »).'
                    },
                    {
                        text: '1.2 This Policy also sets out the rights that you have in relation to the Personal Data that we process about you and how you can exercise them.'
                    },
                    {
                        text: '1.3 AGC treats compliance with its privacy obligations seriously. This is why we have developed this Policy, which describes the standards that AGC applies to protect Personal Data.'
                    },
                    {
                        text: '1.4 For the purposes of this Policy, AGC GLASS EUROPE SA with registered office at Avenue Jean Monnet 4, 1348 Louvain-la-Neuve, Belgium and registered with the Crossroads Bank of Enterprises under number 0413.638.187, and with VAT BE 0413.638.187 (« AGC », « us », « we », or « our » ) acts as the data controller for the Personal Data that is collected via the Website. As a data controller, AGC is responsible for ensuring that the processing of Personal Data complies with applicable data protection law, and specifically with the General Data Protection Regulation.'
                    },
                    {
                        text: `1.5 Please take the time to read this Policy carefully. If you have any questions or comments, please contact ISM Office via ISM.Office@eu.agc.com and/or the Website owner via communications@eu.agc.com.`
                    },
                    {
                        text: 'The types of Personal Data that we may collect about you, and the reasons why we process it, include:'
                    }
                ]
            },
            personalData: {
                title: '2. What Personal Data does AGC collect and why?',
                subtitle: '2.1 The types of Personal Data that we may collect about you, and the reasons why we process it, include:',
                personalDataTable: {
                    reason: {
                        title: 'Why we collect it',
                        content: [
                            {
                                text: 'To enable you to use the functionalities offered by this Website, including e.g. the subscription to the news alert, online applications, etc.'
                            },
                            {
                                text: 'To periodically inform you about or products and services by email or other types of messages (SMS, etc.), in particular with the subscription to our news alerts.'
                            },
                            {
                                text: 'To improve our products and services (drawing up statistics, making market analysis, etc.)'
                            },
                            {
                                text: 'For user management purpose (account management, answering questions, etc.)'
                            }
                        ]
                    },
                    dataType: {
                        title: 'Types of personal data',
                        content: [
                            {
                                text: 'First name, last name, contact information, including phone number, email, address, function/job title, preferred communication language, as the case may be (online applications only), gender.'
                            },
                            {
                                text: 'First name, last name, email address, preferred communication language.'
                            },
                            {
                                text: 'First name, last name, email address, preferred communication language.'
                            },
                            {
                                text: 'First name, last name, email address, preferred communication language.'
                            }
                        ]
                    },
                    legalBasis: {
                        title: 'Legal basis',
                        content: [
                            {
                                text: 'Consent'
                            },
                            {
                                text: 'Consent'
                            },
                            {
                                text: 'Consent'
                            },
                            {
                                text: 'Consent'
                            }
                        ]
                    }
                },
                content: [
                    {
                        text: '2.2 We may also collect certain information automatically from your device. Specifically, the information we collect automatically may include information like your IP address, device type, unique device identification numbers, browser-type, broad geographic location (e.g. country or city-level location) and other technical information. We may also collect information about how your device has interacted with our website, including the pages accessed and links clicked.'
                    },
                    {
                        text: 'Collecting this information enables us to better understand the visitors who come to our website, where they come from, and what content on our website is of interest to them.'
                    },
                    {
                        text: 'We use this information for our internal analytics purposes and to improve the quality and relevance of our website to our visitors.'
                    },
                    {
                        text: 'Some of this information may be collected using cookies and similar tracking technology, as explained further under the heading “Cookies and similar tracking technology” [link] below.]'
                    },
                    {
                        text: '2.3 In general, we will use the Personal Data we collect from you only for the purposes described in this Policy or for purposes that we explain to you at the time we collect your Personal Data.'
                    },
                    {
                        text: 'However, we may also use your Personal Data for other purposes that are not incompatible with the purposes we have disclosed to you (such as archiving purposes in the public interest, scientific or historical research purposes, or statistical purposes) if and where this is permitted by applicable data protection laws.'
                    }
                ]
            },
            sharePersonalData: {
                title: '3. Who does AGC share your Personal Data with ?',
                content: [
                    {
                        text: '3.1 We may disclose your Personal Data to the following categories of recipients:'
                    },
                    {
                        text: '(a) to our group companies for purposes consistent with this Policy. We take precautions to allow access to Personal Data only to those staff members who have a legitimate business need for access and with a contractual prohibition of using the Personal Data for any other purpose.'
                    },
                    {
                        text: '(b) to our third party vendors, services providers and partners who provide data processing services to us, or who otherwise process Personal Data for purposes that are described in this Policy or notified to you when we collect your Personal Data. This may include disclosures to third party vendors and other service providers we use in connection with the services they provide to us, including to support us in areas such as IT platform management or support services, infrastructure and application services, marketing, data analytics;'
                    },
                    {
                        text: '(c) to any competent law enforcement body, regulatory, government agency, court or other third party where we believe disclosure is necessary (i) as a matter of applicable law or regulation, (ii) to exercise, establish or defend our legal rights, or (iii) to protect your vital interests or those of any other person;'
                    },
                    {
                        text: '(d) to our auditors, advisors, legal representatives and similar agents in connection with the advisory services they provide to us for legitimate business purposes and under contractual prohibition of using the Personal Data for any other purpose;'
                    },
                    {
                        text: '(e) to a potential buyer (and its agents and advisers) in connection with any proposed purchase, merger or acquisition of any part of our business, provided that we inform the buyer it must use your Personal Data only for the purposes disclosed in this Policy;'
                    },
                    {
                        text: '(f) to any other person with your prior consent to the disclosure.'
                    }
                ]
            },
            protectPersonalData: {
                title: '4. How we protect your privacy',
                content: [
                    {
                        text: '4.1 We will process Personal Data in accordance with this Policy, as follows (a) Fairness:'
                    },
                    {
                        text: '(a) Fairness: We will process Personal Data fairly. This means that we are transparent about how we process Personal Data and that we will process it in accordance with applicable law.'
                    },
                    {
                        text: '(b) Purpose limitation: We will process Personal Data for specified and lawful purposes, and will not process it in a manner that is incompatible with those purposes.'
                    },
                    {
                        text: '(c) Proportionality: We will process Personal Data in a way that is proportionate to the purposes which the processing is intended to achieve.'
                    },
                    {
                        text: '(d) Data accuracy: We take appropriate measures to ensure that the Personal Data that we hold is accurate, complete and, where necessary, kept up to date. However, it is also your responsibility to ensure that your Personal Data is kept as accurate, complete and current as possible by informing AGC of any changes or errors.'
                    },
                    {
                        text: '(e) Data security: We use appropriate technical and organisational measures to protect the Personal Data that we collect and process about you. The measures we use are designed to provide a level of security appropriate to the risk of processing your Personal Data.'
                    },
                    {
                        text: '(f) Data processors: We may engage third parties to process Personal Data for and on behalf of AGC. We require such data processors to process Personal Data and act strictly on our instructions and to take steps to ensure that Personal Data remains protected.'
                    },
                    {
                        text: '(g) International data transfers: Your Personal Data may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different to the laws of your country (and, in some cases, may not be as protective).'
                    },
                    {
                        text: 'Specifically, our servers are located in the European Economic Area, and our group companies and third party service providers and partners operate including the Russian Federation, Japan, United States. This means that when we collect your Personal Data we may process it in any of these countries.'
                    },
                    {
                        text: 'However, we have taken appropriate safeguards to require that your Personal Data will remain protected in accordance with this Policy. These include implementing an intra- group data transfer agreement (the « Intra-Group Data Transfer Umbrella Agreement ») based on the European Commission’s Standard Contractual Clauses for transfers of Personal Data between our group companies, which require all group companies to protect Personal Data they process from the EEA in accordance with European Union data protection law.'
                    },
                    {
                        text: 'Our Intra-Group Data Transfer Umbrella Agreement can be provided on request. We have implemented similar appropriate safeguards with our third party service providers and partners.'
                    },
                    {
                        text: '(h) Data Retention: We retain Personal Data we collect from you where we have an ongoing legitimate business need to do so (for example, to provide you with a service you have requested or to comply with applicable legal, tax or accounting requirements).'
                    },
                    {
                        text: 'When we have no ongoing legitimate business need to process your Personal Data, we will either delete or anonymise it or, if this is not possible (for example, because your Personal Data has been stored in backup archives), then we will securely store your Personal Data and isolate it from any further processing until deletion is possible.'
                    }
                ]
            },
            dataProtectionRights: {
                title: '5. Your data protection rights',
                content: [
                    {
                        text: '5.1 You have the following data protection rights:'
                    },
                    {
                        text: `(a) If you wish to access, correct, update or request deletion of your Personal Data, you can do so at any time by contacting us using the following contact details ISM Office ${ <span className={ 'legal-terms-link' }>ISM.Office@eu.agc.com</span> } or by contacting your AGC contact person.`
                    },
                    {
                        text: `(b) In addition, in certain circumstances, you can object to processing of your Personal Data, ask us to restrict processing of your Personal Data or request portability of your Personal Data. Again, you can exercise these rights by contacting us using the following contact details ISM Office ${ <span className={ 'legal-terms-link' }>ISM.Office@eu.agc.com</span> } or by contacting your AGC contact person.`
                    },
                    {
                        text: '(c) If we have collected and process your Personal Data with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your Personal Data conducted in reliance on lawful processing grounds other than consent.'
                    },
                    {
                        text: '(d) You have the right to opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the “unsubscribe” or “opt-out” link in the marketing e-mails we send you. To opt-out of other forms of marketing (such as postal marketing or telemarketing), then please contact us using the contact details provided above.'
                    },
                    {
                        text: `(e) If you have a complaint or concern about how we are processing your Personal Data then we will endeavour to address such concern(s). If you feel we have not sufficiently addressed your complaint or concern, you have the right to complain to a data protection authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority. (Contact details for data protection authorities in the European Economic Area, Switzerland and certain non- European countries (including the US and Canada) are available ${ <span className={ 'legal-terms-link' }>here</span> }.)`
                    },
                    {
                        text: '5.2 We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws.'
                    }
                ]
            },
            otherWebsites: {
                title: '6. Linking to other websites',
                content: [
                    {
                        text: '6.1 The Website may contain hyperlinks to websites owned and operated by third parties. These websites have their own privacy policies and we urge you to review them.'
                    },
                    {
                        text: 'They will govern the use of Personal Data you submit whilst visiting these websites.'
                    },
                    {
                        text: 'We do not accept any responsibility or liability for the privacy practices of such third party websites and your use of such websites is at your own risk.'
                    }
                ]
            },
            policyUpdates: {
                title: '7. Updates to this Policy',
                content: [
                    {
                        text: '7.1 We may update this Policy from time to time in response to changing legal, technical or business developments. When we update our Policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material Policy changes if and where this is required by applicable data protection laws.'
                    },
                    {
                        text: '7.2 You can see when this Privacy Policy was last updated by checking the “last updated” date displayed at the top of this Policy.'
                    }
                ]
            }
        },

    },

    cookies: {
        title: 'IV. Use of cookies',
        content: {
            aboutNotice: {
                title: '1. About this Notice',
                content: [
                    {
                        text: '1.1 This Cookie Policy (« Policy ») explains how AGC and its group companies (collectively “AGC”, « we », « us », and « ours ») use cookies and similar technologies to recognise you when you visit our website at [www.reeflect.com] (« Website »). It explains what these technologies are and why we use them, as well as your rights to control our use of them.'
                    },
                    {
                        text: `1.2 Please take the time to read this Policy carefully. If you have any questions or comments, please contact our ISM Office via ISM.Office@eu.agc.com.`
                    }
                ]
            },
            aboutCookies: {
                title: '2. What are cookies?',
                content: [
                    {
                        text: '2.1 Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.'
                    },
                    {
                        text: '2.2 Cookies have many different features, such as allowing you to navigate between pages efficiently, remembering your preferences, and generally improving the user experience. They can also help ensure that the advertisements you see online are more relevant to you and your interests.'
                    },
                    {
                        text: '2.3 Cookies set by the website owner (in this case, AGC) are called « first party cookies ».'
                    },
                    {
                        text: 'Cookies set by parties other than the website owner are called « third party cookies ».'
                    },
                    {
                        text: 'Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). The parties that set these third party cookies can recognise your computer both when it visits the website in question and also when it visits certain other websites.'
                    }
                ]
            },
            cookiesExpiration: {
                title: '3. How long do cookies last ?',
                content: [
                    {
                        text: 'Cookies can remain on your computer or mobile device for different periods of time.'
                    },
                    {
                        text: 'Some cookies are ‘session cookies’, which means they only exist when your browser is open and are automatically deleted when you close your browser or quit the app. Other cookies are ‘persistent cookies’, meaning they survive after your browser or app is closed and can be used by websites or apps to recognize your computer when you re-open your browser or app later. The length of the cookies used on our website is explained in more detail in the table below.'
                    }
                ]
            },
            cookiesUsage: {
                title: '4. Why do we use cookies ?',
                content: [
                    {
                        text: 'When you visit our Website, we may place the following cookies:'
                    }
                ],
                cookiesDataTable: {
                    typeOfCookies: {
                        title: 'Types of cookie',
                        content: [
                            {
                                text: 'Essential website cookies: These cookies are strictly necessary to provide you with services available through our Website and to use some of its features, such as access to secure areas.'
                            },
                            {
                                text: 'Performance and functionality cookies: These cookies are used to enhance the performance and functionality of our Website but are non-essential to their use. However, without these cookies, certain functionality [(like videos)] may become unavailable.'
                            },
                            {
                                text: 'Analytics and customisation cookies: These cookies collect information that is used either in aggregate form to help us understand how our Website are being used or how effective are marketing campaigns are, or to help us customise our Website for you.'
                            },
                            {
                                text: 'Advertising cookies: These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.'
                            },
                            {
                                text: 'Social networking cookies: These cookies are used to enable you to share pages and content that you find interesting on our Website through third party social networking and other websites. These cookies may also be used for advertising purposes too.'
                            }
                        ]
                    },
                    cookiesProvider: {
                        title: 'Who serves these cookies',
                        content: [
                            {
                                text: 'N/A'
                            },
                            {
                                text: 'N/A'
                            },
                            {
                                text: `Yes, with Google Analytics, see https://analytics.google.com`
                            },
                            {
                                text: 'N/A'
                            },
                            {
                                text: 'N/A'
                            }
                        ]
                    },
                    cookiesDuration: {
                        title: 'Duration',
                        content: [
                            {
                                text: 'N/A'
                            },
                            {
                                text: 'N/A'
                            },
                            {
                                text: '_ga expires after 2 years. _gid expires after 1 day.'
                            },
                            {
                                text: 'N/A'
                            },
                            {
                                text: 'N/A'
                            }
                        ]
                    },
                    cookiesCancelation: {
                        title: 'How to refuse',
                        content: [
                            {
                                text: 'N/A'
                            },
                            {
                                text: 'N/A'
                            },
                            {
                                text: 'N/A'
                            },
                            {
                                text: 'N/A'
                            },
                            {
                                text: 'How to refuse'
                            },
                        ]
                    }
                },
            },
            targetedAd: {
                title: '5. What about targeted advertising ?',
                content: [
                    {
                        text: '5.1 Third parties may serve cookies on your computer or mobile device to serve advertising through our Website. These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness of advertisements. This can be accomplished by them using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you. The information collected through this process does not enable us or them to identify your name, contact details or other personally identifying details unless you choose to provide these.'
                    }
                ]
            },
            cookiesControl: {
                title: '6. How can I control cookies ?',
                content: [
                    {
                        text: '6.1 You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by clicking on the appropriate opt-out links provided in the cookie table above.'
                    },
                    {
                        text: '6.2 You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser’s help menu for more information.'
                    }
                ]
            },
            cookiesPast: {
                title: '7. The cookies that were sent in the past',
                content: [
                    {
                        text: '7.1 If you have disabled one or more cookies, we can always use information collected by these cookies before the deactivation. However, we cease to collect information via the opted-out cookie.'
                    }
                ]
            },
            cookiesMore: {
                title: '8. More information about cookies',
                content: [
                    {
                        text: '8.1 For more information about cookies, including explanation about how cookies are placed on your device, or how to manage and delete them, visit http://www.allaboutcookies.org.'
                    }
                ]
            },
            cookiesUpdatePolicy: {
                title: '9. Updates to this Policy',
                content: [
                    {
                        text: '9.1 We may update this Policy from time to time in response to changing legal, technical or business developments. When we update our Policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material changes of this Policy if and where this is required by applicable data protection laws.'
                    },
                    {
                        text: '9.2 You can see when this Policy was last updated by checking the “last updated” date displayed at the top of this Policy.'
                    }
                ]
            }
        }
    },

    preservationOfRights: {
        title: 'V. Preservation of rights and competent jurisdiction',
        content: [
            {
                text: 'AGC reserves the right to take legal action in the event of a breach of the present conditions, with a view to obtaining redress for any damage it may suffer.'
            },
            {
                text: 'Subject to the imperative rules of international public and private law which govern the user, Belgian law governs the relations between AGC and the latter.'
            },
            {
                text: 'The courts of Brussels shall have sole competence.'
            },
            {
                text: 'However, AGC reserves the right to refuse any internet user access to part or all of its site, unilaterally and without prior notification, notably in the event of a manifest breach of the present conditions by an internet user.'
            }
        ]
    },

    miscellaneous: {
        title: 'VI. Miscellaneous',
        content: [
            {
                text: 'Should any individual stipulation in the present conditions be ruled invalid or inapplicable, vis-à-vis the laws in force, such an invalid or inapplicable stipulation will be deemed to be replaced by a valid and applicable stipulation of which the content is as close as possible to that of the original stipulation, and all the other stipulations in the agreement will remain in force.'
            },
            {
                text: 'The present provisions reflect the whole of the agreements between the user and AGC concerning this Website, and they cancel and supersede all previous or contemporary communications and proposals, in electronic, written or oral form, between the user and AGC, concerning this Website.'
            },
            {
                text: 'A printed version of the present provisions and any warning issued in electronic form will be accepted in any judicial or administrative procedure linked to this agreement, in the same way and under the same conditions as other commercial registers and documents created and conserved in printed form.'
            },
            {
                text: 'AGC reserves the right to modify the terms, conditions and warnings by virtue of which this Website is offered.'
            }
        ]
    }
}

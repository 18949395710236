import PropTypes from 'prop-types'
import NavigationItem from './NavigationItem'
import Container from '../Shared/Container'
import NavigationData from '../../data/NavigationData'
import { SALES_LEGAL_TERMS_NAVIGATION_DATA } from '../../data/SalesLegalTermsNavigationData'
import { LEGAL_TERMS_NAVIGATION_DATA } from '../../data/LegalTermsNavigationData'
import { PRIVACY_POLICY_NAVIGATION_DATA } from '../../data/PrivacyPolicyNavigationData'
import { COOKIE_POLICY_NAVIGATION_DATA } from '../../data/CookiePolicyNavigationData'
import './Navigation.css'
import { Fragment } from 'react'

function NavigationList( { route } ) {

    return (
        <Fragment>
            <Container classes={ 'navigation-list' } flex flexColumn gapMedium>
                {
                    ( route === 'sales-legal-terms' )
                    ?
                    <Fragment>
                        {
                            SALES_LEGAL_TERMS_NAVIGATION_DATA.map(
                            ( item, index ) =>
                                (
                                    <NavigationItem key={ index } item={ item } />
                                )
                            )
                        }
                    </Fragment>
                    : (
                        ( route === 'legal-terms' )
                        ?
                        <Fragment>
                            {
                                LEGAL_TERMS_NAVIGATION_DATA.map(
                                ( item, index ) =>
                                    (
                                        <NavigationItem key={ index } item={ item } />
                                    )
                                )
                            }
                        </Fragment>
                        : (
                            ( route === 'privacy-policy' )
                            ?
                            <Fragment>
                                {
                                    PRIVACY_POLICY_NAVIGATION_DATA.map(
                                    ( item, index ) =>
                                        (
                                            <NavigationItem key={ index } item={ item } />
                                        )
                                    )
                                }
                            </Fragment>
                            : (
                                ( route === 'cookie-policy' )
                                ?
                                <Fragment>
                                    {
                                        COOKIE_POLICY_NAVIGATION_DATA.map(
                                        ( item, index ) =>
                                            (
                                                <NavigationItem key={ index } item={ item } />
                                            )
                                        )
                                    }
                                </Fragment>
                                :
                                <Fragment>
                                    {
                                        NavigationData.map(
                                        ( item, index ) =>
                                            (
                                                <NavigationItem key={ index } item={ item } />
                                            )
                                        )
                                    }
                                </Fragment>
                                )
                            )
                        )
                }
            </Container>
        </Fragment>
    )
}

NavigationList.propTypes =
    {
        item: PropTypes.array
    }

export default NavigationList

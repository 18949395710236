import Title from '../../Shared/Title'
import Container from '../../Shared/Container'
import { LEGAL_TERMS_DATA } from '../../../data/legalTermsData'

import './general.conditions.styles.css'

const GeneralConditions = ( { id } ) => {
    const { title, content } = LEGAL_TERMS_DATA.generalConditions

    return (
        <Container classes={ 'general-conditions' } flex flexColumn id={ id }>
            <Title size={ 'block-title' }>
                <h5>{ title }</h5>
            </Title>
            <Container classes={ 'general-conditions-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.accessibility.title }</h4>
                {
                    content.accessibility.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'general-conditions-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.limitation.title }</h4>
                {
                    content.limitation.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'general-conditions-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.intelectualProperty.title }</h4>
                {
                    content.intelectualProperty.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'general-conditions-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.thirdPartyWebsites.title }</h4>
                {
                    content.thirdPartyWebsites.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
        </Container>
    )
}

export default GeneralConditions

import React, {  useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import './CookieConsent.css';

const PERFORMANCE_COOKIE_CONSENT_KEY = 'performanceCookieConsent';
const FUNCTIONAL_COOKIE_CONSENT_KEY = 'functionalCookieConsent';
const TARGETING_COOKIE_CONSENT_KEY = 'targetingCookieConsent';
const GOOGLE_ANALYTICS_TRACKING_ID = 'G-HKECN1XSQ4';

let googleAnalyticsTrackingScript = null;
let googleAnalyticsTrackingIsEnabled = false;

function gtag() {
    window.dataLayer.push( arguments );
}

function loadGoogleAnalytics( trackingId, consent ) {
    if ( !googleAnalyticsTrackingIsEnabled ) {
        googleAnalyticsTrackingIsEnabled = true;
        googleAnalyticsTrackingScript = document.createElement( 'script' );
        googleAnalyticsTrackingScript.async = true;
        googleAnalyticsTrackingScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + trackingId;

        document.head.appendChild( googleAnalyticsTrackingScript );

        window.dataLayer = window.dataLayer || [];

        gtag(
            'consent',
            'default',
            {
                'ad_storage': consent ? 'granted' : 'denied',
                'analytics_storage': consent ? 'granted' : 'denied',
                'functionality_storage': consent ? 'granted' : 'denied',
                'personalization_storage': consent ? 'granted' : 'denied',
            }
            );

        gtag( 'js', new Date() );
        gtag( 'config', trackingId );

        gtag(
            'consent',
            'update',
            {
               'ad_storage': consent ? 'granted' : 'denied',
               'analytics_storage': consent ? 'granted' : 'denied',
               'functionality_storage': consent ? 'granted' : 'denied',
               'personalization_storage': consent ? 'granted' : 'denied',
            }
            );
    }
}

function updateGoogleAnalyticsConsent( consent ) {
     gtag( 'consent', 'update', {
            'ad_storage': consent ? 'granted' : 'denied',
            'analytics_storage': consent ? 'granted' : 'denied',
            'functionality_storage': consent ? 'granted' : 'denied',
            'personalization_storage': consent ? 'granted' : 'denied',
         });
}

function unloadGoogleAnalytics()
{
    if ( googleAnalyticsTrackingIsEnabled )
    {
        if ( googleAnalyticsTrackingScript )
        {
            document.head.removeChild( googleAnalyticsTrackingScript );
        }

        window.dataLayer = [];
        googleAnalyticsTrackingIsEnabled = false;
        googleAnalyticsTrackingScript = null;
    }
}

const CookieConsent = () => {
    const [ mode, setMode ] = useState( 'banner' );
    const [ hasAllowAllCookiesButton, setHasAllowAllCookiesButton ] = useState( true );
    const [ hasRequiredCookiesDescription, setHasRequiredCookiesDescription ] = useState( false );
    const [ hasPerformanceCookiesDescription, setHasPerformanceCookiesDescription ] = useState( false );
    const [ hasFunctionalCookiesDescription, setHasFunctionalCookiesDescription ] = useState( false );
    const [ hasTargetingCookiesDescription, setHasTargetingCookiesDescription ] = useState( false );

    const toggleHasRequiredCookiesDescription = () => {
        setHasRequiredCookiesDescription( !hasRequiredCookiesDescription );
        setHasPerformanceCookiesDescription( false );
        setHasFunctionalCookiesDescription( false );
        setHasTargetingCookiesDescription( false );
    };

    const toggleHasPerformanceCookiesDescription = () => {
        setHasRequiredCookiesDescription( false );
        setHasPerformanceCookiesDescription( !hasPerformanceCookiesDescription );
        setHasFunctionalCookiesDescription( false );
        setHasTargetingCookiesDescription( false );
    };

    const toggleHasFunctionalCookiesDescription = () => {
        setHasRequiredCookiesDescription( false );
        setHasPerformanceCookiesDescription( false );
        setHasFunctionalCookiesDescription( !hasFunctionalCookiesDescription );
        setHasTargetingCookiesDescription( false );
    };

    const toggleHasTargetingCookiesDescription = () => {
        setHasRequiredCookiesDescription( false );
        setHasPerformanceCookiesDescription( false );
        setHasFunctionalCookiesDescription( false );
        setHasTargetingCookiesDescription( !hasTargetingCookiesDescription );
    };

    const hasPerformanceCookieConsent = () => {
        return localStorage.getItem( PERFORMANCE_COOKIE_CONSENT_KEY ) !== null;
    };

    const getPerformanceCookieConsent = () => {
        return localStorage.getItem( PERFORMANCE_COOKIE_CONSENT_KEY ) === 'true';
    };

    const setPerformanceCookieConsent = ( cookieConsent ) => {
        localStorage.setItem( PERFORMANCE_COOKIE_CONSENT_KEY, cookieConsent ? 'true' : 'false' );
    };

    const hasFunctionalCookieConsent = () => {
        return localStorage.getItem( FUNCTIONAL_COOKIE_CONSENT_KEY ) !== null;
    };

    const getFunctionalCookieConsent = () => {
        return localStorage.getItem( FUNCTIONAL_COOKIE_CONSENT_KEY ) === 'true';
    };

    const setFunctionalCookieConsent = ( cookieConsent ) => {
        localStorage.setItem( FUNCTIONAL_COOKIE_CONSENT_KEY, cookieConsent ? 'true' : 'false' );
    };

    const hasTargetingCookieConsent = () => {
        return localStorage.getItem( TARGETING_COOKIE_CONSENT_KEY ) !== null;
    };

    const getTargetingCookieConsent = () => {
        return localStorage.getItem( TARGETING_COOKIE_CONSENT_KEY ) === 'true';
    };

    const setTargetingCookieConsent = ( cookieConsent ) => {
        localStorage.setItem( TARGETING_COOKIE_CONSENT_KEY, cookieConsent ? 'true' : 'false' );
    };

    const hasCookieConsent = () => {
        return (
            hasPerformanceCookieConsent()
            || hasFunctionalCookieConsent()
            || hasTargetingCookieConsent()
            );
    }

    const getCookieConsent = () => {
        return (
            getPerformanceCookieConsent()
            || getFunctionalCookieConsent()
            || getTargetingCookieConsent()
            );
    }

    const setCookieConsent = ( cookieConsent ) => {
        setPerformanceCookieConsent( cookieConsent );
        setFunctionalCookieConsent( cookieConsent );
        setTargetingCookieConsent( cookieConsent );
    }

    const hideDescriptions = () => {
        setHasRequiredCookiesDescription( false );
        setHasPerformanceCookiesDescription( false );
        setHasFunctionalCookiesDescription( false );
        setHasTargetingCookiesDescription( false );
    };

    useEffect( () => {
        hideDescriptions();

        setHasAllowAllCookiesButton(
            !getPerformanceCookieConsent()
            || !getFunctionalCookieConsent()
            || !getTargetingCookieConsent()
            );

        if ( hasCookieConsent() ) {
            showButton();
            loadGoogleAnalytics( GOOGLE_ANALYTICS_TRACKING_ID, getCookieConsent() );
        }
        else
        {
            unloadGoogleAnalytics();
            loadGoogleAnalytics( GOOGLE_ANALYTICS_TRACKING_ID, false );
        }
    }, [] );

    const acceptAllCookies = () => {
        setCookieConsent( true );
        showButton();
        updateGoogleAnalyticsConsent(true);
    };

    const refuseAllCookies = () => {
        setCookieConsent( false );
        showButton();
        unloadGoogleAnalytics();
        loadGoogleAnalytics( GOOGLE_ANALYTICS_TRACKING_ID, false );
    };

    const isPerformanceCookieConsentChecked = () => {
        return document.getElementById( 'performance-cookies' ).checked;
    };

    const isFunctionalCookieConsentChecked = () => {
        return document.getElementById( 'functional-cookies' ).checked;
    };

    const isTargetingCookieConsentChecked = () => {
        return document.getElementById( 'targeting-cookies' ).checked;
    };

    const toggleAllowAllCookiesButton = () => {
        setHasAllowAllCookiesButton(
            !isPerformanceCookieConsentChecked()
            || !isFunctionalCookieConsentChecked()
            || !isTargetingCookieConsentChecked()
            );
    };

    const confirmChoices = () => {
         const performanceConsent = isPerformanceCookieConsentChecked();
        const functionalConsent = isFunctionalCookieConsentChecked();
        const targetingConsent = isTargetingCookieConsentChecked();
        const hasAnyConsent = performanceConsent || functionalConsent || targetingConsent;

        setPerformanceCookieConsent( isPerformanceCookieConsentChecked() );
        setFunctionalCookieConsent( isFunctionalCookieConsentChecked() );
        setTargetingCookieConsent( isTargetingCookieConsentChecked() );
        showButton();
        updateGoogleAnalyticsConsent(hasAnyConsent);

        if (!hasAnyConsent)
        {
            unloadGoogleAnalytics();
        }
    };

    const showBanner = () => {
        setMode( 'banner' );
    }

    const showButton = () => {
        setMode( 'button' );
    }

    const showDialog = () => {
        hideDescriptions();
        setMode( 'dialog' );
    }

    return (
        <>
            { mode === 'banner' && (
                <div className='cookie-consent-banner'>
                    <div className='cookie-consent-banner-text'>
                        By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.
                    </div>
                    <div className='cookie-consent-banner-link'>
                        <Link to='/cookie-policy' target='_blank' rel='noreferrer'>
                            More information
                        </Link>
                    </div>
                    <div className='cookie-consent-banner-button-list'>
                        <div className='cookie-consent-banner-button cookie-consent-banner-accept-cookies-button' onClick={ () => acceptAllCookies() }>
                            Accept All Cookies
                        </div>
                        <div className='cookie-consent-banner-button cookie-consent-banner-reject-cookies-button' onClick={ () => refuseAllCookies() }>
                            Reject All
                        </div>
                        <div className='cookie-consent-banner-button cookie-consent-banner-cookies-settings-button' onClick={ () => showDialog() }>
                            Cookies Settings
                        </div>
                    </div>
                </div>
            ) }

            { mode === 'button' && (
                <div className='cookie-consent-button' style={{background: 'url( "./image/cookie_consent/cookie_button.svg" ) no-repeat center center / contain'}} onClick={ () => showDialog() }>
                    <div className='cookie-consent-button-tooltip'>
                        Cookies Settings
                    </div>
                </div>
            ) }

            { mode === 'dialog' && (
                <div id='cookie-consent-dialog-container' className='cookie-consent-dialog-container is-hidden'>
                    <div className='cookie-consent-dialog'>
                        <div className='cookie-consent-dialog-header'>
                            <img className='cookie-consent-dialog-header-image' src='./image/cookie_consent/logo.svg' alt=''/>
                            <div className='cookie-consent-dialog-header-title'>
                                Privacy Preference Center
                            </div>
                            <div className='cookie-consent-dialog-header-close-button' style={{background: 'url( "./image/cookie_consent/close_icon.svg" ) no-repeat center center / contain'}} onClick={ () => showButton() }>
                            </div>
                        </div>
                        <div className='cookie-consent-dialog-content'>
                            <div className='cookie-consent-dialog-description'>
                                When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies.
                                This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to.
                                The information does not usually directly identify you, but it can give you a more personalized web experience.
                                Because we respect your right to privacy, you can choose not to allow some types of cookies.
                                Click on the different category headings to find out more and change our default settings.
                                However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.
                            </div>
                            <div className='cookie-consent-banner-link'>
                                <Link to='/cookie-policy' target='_blank' rel='noreferrer'>
                                    More information
                                </Link>
                            </div>
                            { hasAllowAllCookiesButton && (
                                <div className='cookie-consent-dialog-button-list'>
                                    <div className='cookie-consent-dialog-button' onClick={ () => acceptAllCookies() }>
                                        Allow All
                                    </div>
                                </div>
                            ) }
                            <div className='cookie-consent-dialog-setting-list'>
                                <div className='cookie-consent-dialog-setting'>
                                    <div className='cookie-consent-dialog-setting-title is-grayed' onClick={ () => toggleHasRequiredCookiesDescription() }>
                                        <img className='cookie-consent-dialog-setting-title-image' src='./image/cookie_consent/plus_icon.svg' alt=''/>
                                        Strictly Necessary Cookies
                                    </div>
                                    <div className='cookie-consent-dialog-setting-value'>
                                        Always active
                                    </div>
                                    { hasRequiredCookiesDescription && (
                                        <div className='cookie-consent-dialog-setting-description is-hidden' onClick={ () => toggleHasRequiredCookiesDescription() }>
                                            These cookies are necessary for the website to function and cannot be switched off in our systems.
                                            They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms.
                                            You can set your browser to block or alert you about these cookies, but some parts of the site will not then work.
                                            These cookies do not store any personally identifiable information.
                                        </div>
                                    ) }
                                </div>
                                <div className='cookie-consent-dialog-setting'>
                                    <div className='cookie-consent-dialog-setting-title' onClick={ () => toggleHasPerformanceCookiesDescription() }>
                                        <img className='cookie-consent-dialog-setting-title-image' src='./image/cookie_consent/plus_icon.svg' alt=''/>
                                        Performance Cookies
                                    </div>
                                    <div className='cookie-consent-dialog-setting-value'>
                                        <label className='cookie-consent-dialog-setting-switch'>
                                            <input id='performance-cookies' className='cookie-consent-dialog-setting-input' type='checkbox' defaultChecked={ getPerformanceCookieConsent() } onChange={ toggleAllowAllCookiesButton } />
                                            <span className='cookie-consent-dialog-setting-slider'></span>
                                        </label>
                                    </div>
                                    { hasPerformanceCookiesDescription && (
                                        <div className='cookie-consent-dialog-setting-description is-hidden' onClick={ () => toggleHasPerformanceCookiesDescription() }>
                                            These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site.
                                            They help us to know which pages are the most and least popular and see how visitors move around the site.
                                            All information these cookies collect is aggregated and therefore anonymous.
                                            If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.
                                        </div>
                                    ) }
                                </div>
                                <div className='cookie-consent-dialog-setting'>
                                    <div className='cookie-consent-dialog-setting-title' onClick={ () => toggleHasFunctionalCookiesDescription() }>
                                        <img className='cookie-consent-dialog-setting-title-image' src='./image/cookie_consent/plus_icon.svg' alt=''/>
                                        Functional Cookies
                                    </div>
                                    <div className='cookie-consent-dialog-setting-value'>
                                        <label className='cookie-consent-dialog-setting-switch'>
                                            <input id='functional-cookies' className='cookie-consent-dialog-setting-input' type='checkbox' defaultChecked={ getFunctionalCookieConsent() } onChange={ toggleAllowAllCookiesButton } />
                                            <span className='cookie-consent-dialog-setting-slider'></span>
                                        </label>
                                    </div>
                                    { hasFunctionalCookiesDescription && (
                                        <div className='cookie-consent-dialog-setting-description is-hidden' onClick={ () => toggleHasFunctionalCookiesDescription() }>
                                            These cookies enable the website to provide enhanced functionality and personalisation.
                                            They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.
                                        </div>
                                    ) }
                                </div>
                                <div className='cookie-consent-dialog-setting'>
                                    <div className='cookie-consent-dialog-setting-title' onClick={ () => toggleHasTargetingCookiesDescription() }>
                                        <img className='cookie-consent-dialog-setting-title-image' src='./image/cookie_consent/plus_icon.svg' alt=''/>
                                        Targeting Cookies
                                    </div>
                                    <div className='cookie-consent-dialog-setting-value'>
                                        <label className='cookie-consent-dialog-setting-switch'>
                                            <input id='targeting-cookies' className='cookie-consent-dialog-setting-input' type='checkbox' defaultChecked={ getTargetingCookieConsent() } onChange={ toggleAllowAllCookiesButton } />
                                            <span className='cookie-consent-dialog-setting-slider'></span>
                                        </label>
                                    </div>
                                    { hasTargetingCookiesDescription && (
                                        <div className='cookie-consent-dialog-setting-description is-hidden' onClick={ () => toggleHasTargetingCookiesDescription() }>
                                            These cookies may be set through our site by our advertising partners.
                                            They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.
                                            They do not store directly personal information, but are based on uniquely identifying your browser and internet device.
                                            If you do not allow these cookies, you will experience less targeted advertising.
                                        </div>
                                    ) }
                                </div>
                            </div>
                        </div>
                        <div className='cookie-consent-dialog-right-button-list'>
                            <div className='cookie-consent-dialog-button' onClick={ () => refuseAllCookies() }>
                                Reject All
                            </div>
                            <div
                                className='cookie-consent-dialog-button' onClick={ () => confirmChoices() }
                            >
                                Confirm My Choices
                            </div>
                        </div>
                    </div>
                </div>
            ) }
        </>
     );
};

export default CookieConsent;

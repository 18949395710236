import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomeScreen from './screens/HomeScreen'
import ErrorScreen from './screens/ErrorScreen'
import SalesLegalTerms from './screens/SalesLegalTerms'
import LegalTerms from './screens/LegalTerms'
import PrivacyPolicy from './screens/PrivacyPolicy'
import CookiePolicy from './screens/CookiePolicy'

function App() {

    return (
        <Router>

            <Routes>

                <Route exact path='/' element={ <HomeScreen /> } />
                <Route exact path='/sales-legal-terms' element={ <SalesLegalTerms /> } />
                <Route exact path='/legal-terms' element={ <LegalTerms /> } />
                <Route exact path='/privacy-policy' element={ <PrivacyPolicy /> } />
                <Route exact path='/cookie-policy' element={ <CookiePolicy /> } />
                <Route path='*' element={ <ErrorScreen /> } />

            </Routes>

        </Router>
    )
}

export default App

import Title from '../../Shared/Title'
import Container from '../../Shared/Container'
import { LEGAL_TERMS_DATA } from '../../../data/legalTermsData'

import './identification.styles.css'

const IdentificationSection = ( { id } ) => {
    const { title, content } = LEGAL_TERMS_DATA.identification

    return (
        <Container classes={ 'identification-section' } flex flexColumn id={ id }>
            <Title size={ 'block-title' }>
                <h5>{ title }</h5>
            </Title>
            {
                content.map( ( text, index ) => (
                    <p key={ index }>{ text.text }</p>
                ) )
            }
        </Container>
    )
}

export default IdentificationSection

import { useState, useEffect, useRef, Fragment } from 'react'
import Header from '../components/Header/Header'
import Container from '../components/Shared/Container'
import Title from '../components/Shared/Title'
import Loader from '../components/Shared/Loader'
import Background from '../threejs/Background'
import Footer from '../components/Footer/Footer'
import Navigation from '../components/Navigation/Navigation'
import CookieConsent from '../components/CookieConsent/CookieConsent'

const PrivacyPolicy = () => {
    const [ loading, setLoading ] = useState( true )
    const isMounted = useRef( true )
    useEffect( () => {
        setTimeout( () => {
            if ( isMounted.current ) {
                setLoading( false )
            }
        }, 3000 )
        return () =>
        {
            isMounted.current = false
        }
    }, [ isMounted ] )
    return (
        <Fragment>
            {
                loading
                ?
                <Loader />
                :
                <Fragment>
                    <Background />
                    <Navigation route={ 'privacy-policy' } />
                    <Header home={ false } />
                    <Container classes={ 'home-screen-container' }>
                        <Container classes={ 'hero-section' } flex spaceBetween id={ 'top' }>
                            <Title size={ 'large is-visible' }>
                                <h1>Privacy</h1>
                                <h2>Policy</h2>
                            </Title>
                        </Container>
                        <Container classes={ '' } flex flexColumn id={ '' }>
                            <div class="content-section">
                                <div class="content-section-title">1. About this notice</div>
                                <ol class="content-section-list">
                                    <li class="content-section-line">This Privacy Notice ("<em>Notice</em>") explains how we (as defined below) as a "<em>Data Controller</em>"<sup>1</sup> collect, share and use ("<em>Process</em>"<sup>2</sup> )
                                    any information that, alone or in combination with other information, relates to you ("<em>Personal Data</em>"<sup>3</sup> ) when you ("<em>you</em>" and "<em>your</em>") use our websites that reference this Notice (each a "<em>Website</em>").
                                    </li>
                                    <li class="content-section-line">This Notice also sets out the rights that you have in relation to the Personal Data that we process about you and how you can exercise them.</li>
                                    <li class="content-section-line">AGC treats compliance with its privacy obligations seriously. This is why we have developed this Notice, which describes the standards that AGC applies to protect Personal Data.</li>
                                    <li class="content-section-line">For the purposes of this Notice, AGC Glass Europe SA with registered office at 4, avenue Jean Monnet, 1348 Louvain-la-Neuve, Belgium, and registered with the Crossroads Bank of Enterprises (RPM) under number 0413.638.187, and with VAT BE 0413.638.187 ("AGC", "<em>us</em>", "<em>we</em>", or "<em>our</em>" ) acts as the Data Controller, either on its own behalf or on behalf of the "<em>AGC Group</em>"<sup>4</sup> company managing the relevant Website, for the Personal Data that is collected via the Website. As a Data Controller, AGC is responsible for ensuring that the processing of Personal Data complies with applicable data protection law, and specifically with the General Data Protection Regulation (GDPR).
                                    </li>
                                    <li class="content-section-line">Please take the time to read this Notice carefully. If you have any questions or comments, please contact us via <a href="mailto:AGC.ISM.Office@agc.com">AGC.ISM.Office@agc.com</a>.
                                    </li>
                                </ol>
                                <div class="content-section-paragraph"><sup>1</sup> "Data Controller" means AGC (as defined herein), to the extent that we determine the purposes and means of the processing of your Personal Data.</div>
                                <div class="content-section-paragraph"><sup>2</sup> "Processing" means everything we do with your Personal Data as further explained in this Privacy Notice. It may include actions such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</div>
                                <div class="content-section-paragraph"><sup>3</sup> "Personal Data" is information through which a natural person is identifiable or may be identified. AGC collects, uses and processes your Personal Data in order to provide you with services, products or information that you request.</div>
                                <div class="content-section-paragraph"><sup>4</sup> AGC Group includes both AGC Europe SA, AGC Glass Europe SA and their respective affiliated entities that are incorporated within the European Economic Area (EEA).</div>
                            </div>

                            <div class="content-section">
                                <div class="content-section-title">2. What Personal Data does AGC collect and why?</div>
                                <ol class="content-section-list">
                                    <li class="content-section-line">
                                        <div class="content-section-paragraph">The types of Personal Data that we collect about you, and the reasons why we process them, are as follows:</div>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Why we collect it</th>
                                                    <th>Types of Personal Data</th>
                                                    <th>Legal basis</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                 <tr>
                                                    <td>To enable you to use the functionalities offered by this Website, configuration tools for glass products adapted to your needs and receiving offers and quotations.</td>
                                                    <td>First name, last name, contact information, including phone number, email, address, function/job title, preferred communication language.</td>
                                                    <td>Consent</td>
                                                </tr>
                                                <tr>
                                                    <td>To periodically inform you about or products and services by email or other types of messages (SMS, voicemail, whatsapp), in particular with the subscription to our news alerts.</td>
                                                    <td>First name, last name, email address, telephone number, preferred communication language.</td>
                                                    <td>Consent</td>
                                                </tr>
                                                 <tr>
                                                    <td>To improve our products and services (drawing up statistics, making market analysis, processing product feedback).</td>
                                                   <td>First name, last name, email address, preferred communication language.</td>
                                                    <td>Consent or Legitimate Interest (LIA available on demand)</td>
                                                </tr>
                                                 <tr>
                                                    <td>To monitor and visualize your interaction with us on e-mail correspondence you receive from us (via Microsoft Dynamics) in order to improve the relevance and quality of our communications with you.</td>
                                                     <td>E-mail address</td>
                                                    <td>Legitimate Interest (LIA available on demand)</td>
                                                </tr>
                                                <tr>
                                                     <td>For user management purpose (account management, answering questions).</td>
                                                     <td>First name, last name, email address, preferred communication language.</td>
                                                     <td>Consent</td>
                                                </tr>
                                                 <tr>
                                                     <td>Process your request when you apply for one of our open positions.</td>
                                                     <td>First name, last name, email address, preferred communication language, resume details (personal address, career information, personal information as shared by you).</td>
                                                    <td>Consent</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </li>
                                     <li class="content-section-line">
                                        <div class="content-section-paragraph">We also collect certain information automatically from your device. Specifically, the information we collect automatically is: your IP address, device type, unique device identification numbers, browser-type, broad geographic location (e.g. country or city-level location) and other technical information. We also collect information about how your device has interacted with our website, including the pages accessed and links clicked. Collecting this information enables us to understand better the visitors who come to our website, where they come from, and what content on our website is of interest to them. We use this information for our internal analytics purposes and to improve the quality and relevance of our website to our visitors. Some of this information may be collected using cookies and similar tracking technology, as explained further under the heading "Cookies" available in the footer of our websites.</div>
                                    </li>
                                    <li class="content-section-line">
                                        <div class="content-section-paragraph">From time to time, we receive Personal Data about you from third party sources. This happens at commercial fairs, industry conferences, from recruitment websites, but only where we have checked that these third parties either have your consent or are otherwise legally permitted or required to disclose your Personal Data to us. The types of Personal Data we collect from third parties include contact details, type of business, etc. and we use this Personal Data we receive from these third parties to maintain and improve the accuracy of the records we hold about you.</div>
                                    </li>
                                    <li class="content-section-line">
                                        <div class="content-section-paragraph">In general, we will use the Personal Data we collect from you only for the purposes described in this Notice or for purposes that we explain to you at the time we collect your Personal Data. However, we may also use your Personal Data for other purposes that are not incompatible with the purposes we have disclosed to you (such as archiving purposes in the public interest, scientific or historical research purposes, or statistical purposes) if and where this is permitted by applicable data protection laws:</div>
                                        <ol class="content-section-list">
                                            <li class="content-section-line">To improve the functionalities offered by this Website;</li>
                                            <li class="content-section-line">In the future, we might collect your personal data to enrich other data records that we hold about our clients, such as e.g. our customer relationship management systems or our SAP systems, with the view of better addressing your needs or with the view of better serving you (e.g. better executing your orders);</li>
                                         </ol>
                                    </li>
                                </ol>
                            </div>

                            <div class="content-section">
                                 <div class="content-section-title">3. With whom does AGC share your Personal Data?</div>
                                <ol class="content-section-list">
                                    <li class="content-section-line">
                                        <div class="content-section-paragraph">We disclose your Personal Data to the following categories of recipients:</div>
                                        <ol class="content-section-list">
                                            <li class="content-section-line">to <em>our AGC Group companies</em> for purposes consistent with this Notice. We take precautions to allow access to Personal Data only to those staff members who have a legitimate business need for access and with a contractual prohibition of using the Personal Data for any other purpose;</li>
                                             <li class="content-section-line">to <em>our third party vendors, services providers and partners</em> who provide data processing services to us, or who otherwise process Personal Data for purposes that are described in this Notice or notified to you when we collect your Personal Data;</li>
                                            <li class="content-section-line">this may include disclosures to third party vendors and other service providers we use in connection with the services they provide to us, including to support us in areas such as IT platform management or support services, infrastructure and application services, marketing, data analytics;</li>
                                             <li class="content-section-line">to <em>any competent law enforcement body, regulatory, government agency, court or other third party</em> where we believe disclosure is necessary (i) as a matter of applicable law or regulation, (ii) to exercise, establish or defend our legal rights, or (iii) to protect your vital interests or those of any other person;</li>
                                            <li class="content-section-line">to <em>our auditors, advisors, legal representatives and similar agents</em> in connection with the advisory services they provide to us for legitimate business purposes and under contractual prohibition of using the Personal Data for any other purpose;</li>
                                            <li class="content-section-line">to <em>a potential buyer</em> (and its agents and advisers) in connection with any proposed purchase, merger or acquisition of any part of our business, provided that we inform the buyer it must use your Personal Data only for the purposes disclosed in this Notice;</li>
                                            <li class="content-section-line">to any other person with your prior consent to the disclosure.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>

                            <div class="content-section">
                                <div class="content-section-title">4. How we protect your privacy</div>
                                <ol class="content-section-list">
                                    <li class="content-section-line">
                                       <div class="content-section-paragraph">We will process Personal Data in accordance with this Notice, as follows:</div>
                                       <ol class="content-section-list">
                                           <li class="content-section-line"><em>Fairness</em>: We will process Personal Data fairly. This means that we are transparent about how we process Personal Data and that we will process it in accordance with applicable law.</li>
                                           <li class="content-section-line"><em>Purpose limitation</em>: We will process Personal Data for specified and lawful purposes, and will not process it in a manner that is incompatible with those purposes.</li>
                                           <li class="content-section-line"><em>Proportionality</em>: We will process Personal Data in a way that is proportionate to the purposes which the processing is intended to achieve.</li>
                                            <li class="content-section-line"><em>Data accuracy</em>: We take appropriate measures to ensure that the Personal Data that we hold is accurate, complete and, where necessary, kept up to date. However, it is also your responsibility to ensure that your Personal Data is kept as accurate, complete and current as possible by informing AGC of any changes or errors.</li>
                                           <li class="content-section-line"><em>Data security</em>: We use appropriate technical and organisational measures to protect the Personal Data that we collect and process about you. The measures we use are designed to provide a level of security appropriate to the risk of processing your Personal Data.</li>
                                           <li class="content-section-line"><em>Data processors</em>: We may engage third parties to process Personal Data for and on behalf of AGC. We require such data processors to process Personal Data and act strictly on our instructions and to take steps to ensure that Personal Data remains protected.</li>
                                           <li class="content-section-line"><em>International data transfers</em>: Your Personal Data may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different to the laws of your country (and, in some cases, may not be as protective). Specifically, our servers are located in the European Economic Area, and our group companies and third party service providers and partners operate around the world, including in Japan, United States, etc. This means that when we collect your Personal Data we may process it in any of these countries. However, we have taken appropriate safeguards to require that your Personal Data will remain protected in accordance with this Notice. These include implementing an intra-group data transfer agreement (the "<em>Intra-Group Data Transfer Umbrella Agreement</em>") based on the European Commission’s Standard Contractual Clauses for transfers of Personal Data between our group companies, which require all group companies to protect Personal Data they process from the EEA in accordance with European Union data protection law. Our Intra-Group Data Transfer Umbrella Agreement can be provided on request. We have implemented similar appropriate safeguards with our third party service providers and partners.
                                           </li>
                                            <li class="content-section-line"><em>Data Retention</em>: We retain Personal Data we collect from you where we have an ongoing legitimate business need to do so (for example, to provide you with a service you have requested or to comply with applicable legal, tax or accounting requirements). When we have no ongoing legitimate business need to process your Personal Data, we will either delete or anonymise it or, if this is not possible (for example, because your Personal Data has been stored in backup archives), then we will securely store your Personal Data and isolate it from any further processing until deletion is possible.
                                            </li>
                                       </ol>
                                    </li>
                                </ol>
                            </div>

                            <div class="content-section">
                                  <div class="content-section-title">5. Your data protection rights</div>
                                <ol class="content-section-list">
                                    <li class="content-section-line">
                                        <div class="content-section-paragraph">You have the following data protection rights:</div>
                                        <ol class="content-section-list">
                                            <li class="content-section-line">If you wish to <em>access, correct, update or request deletion</em> of your Personal Data, you can do so at any time by contacting us using the following contact details <a href="mailto:AGC.ISM.Office@agc.com">AGC.ISM.Office@agc.com</a> or by contacting your AGC contact person.</li>
                                            <li class="content-section-line">In addition, in certain circumstances, you can <em>object to processing</em> of your Personal Data, ask us to <em>restrict processing</em> of your Personal Data or <em>request portability</em> of your Personal Data. Again, you can exercise these rights by contacting us using the following contact details <a href="mailto:AGC.ISM.Office@agc.com">AGC.ISM.Office@agc.com</a> or by contacting your AGC contact person.</li>
                                            <li class="content-section-line">If we have collected and process your Personal Data with your consent, then you can <em>withdraw your consent</em> at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your Personal Data conducted in reliance on lawful processing grounds other than consent.</li>
                                            <li class="content-section-line">You have the <em>right to opt-out of marketing communications</em> we send you at any time. You can exercise this right by clicking on the "unsubscribe" or "opt-out" link in the marketing e-mails we send you. To opt-out of other forms of marketing (such as postal marketing or telemarketing), then please contact us using the contact details provided above.</li>
                                            <li class="content-section-line">If you have a complaint or concern about how we are processing your Personal Data then we will endeavour to address such concern(s). If you feel we have not sufficiently addressed your complaint or concern, you have the right to complain to a data protection authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority. (Contact details for data protection authorities in the European Economic Area are available <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en">here</a>).</li>
                                        </ol>
                                    </li>
                                    <li class="content-section-line">
                                        <div class="content-section-paragraph">We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws.</div>
                                    </li>
                                </ol>
                            </div>

                            <div class="content-section">
                                <div class="content-section-title">6. Linking to other websites</div>
                                <ol class="content-section-list">
                                     <li class="content-section-line">The Website may contain hyperlinks to websites owned and operated by third parties. These websites have their own privacy policies and we urge you to review them. They will govern the use of Personal Data you submit whilst visiting these websites. We do not accept any responsibility or liability for the privacy practices of such third party websites and your use of such websites is at your own risk.
                                     </li>
                                     <li class="content-section-line">We also display social media buttons on our Websites. When you click on any of those buttons, your personal data may be transferred to these companies and they may also set cookies or other tracking technologies on your browser. The privacy policies and terms of use of each of those companies govern the collection and use of your personal data when you click on their buttons on our Websites.
                                    </li>
                                     <li class="content-section-line">Prior to installing third party cookies or enabling you to click through to a third party website, we will have requested your consent to do so. Where consent was not given, the cookies will not be installed and the click through to third party websites will only occur after a pop-up notifying you that you are leaving the AGC space and requesting whether you wish to continue.
                                    </li>
                                </ol>
                            </div>

                            <div class="content-section">
                                <div class="content-section-title">7. Notice for Parents and Guardians</div>
                                <ol class="content-section-list">
                                    <li class="content-section-line">Our website is intended for visitors who are at least 18 years of age, or the age of majority in their jurisdiction of residence. AGC does not knowingly solicit information from or market products or services to children. If you do not meet the age requirements set out above, please do not enter your Personal Data on this website.
                                    </li>
                                </ol>

                                  <div class="content-section-title">8. Updates to this Notice</div>
                                 <ol class="content-section-list">
                                    <li class="content-section-line">We may update this Notice from time to time in response to changing legal, technical or business developments. When we update our Notice, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material Notice changes if and where this is required by applicable data protection laws. You can see when this Privacy Notice was last updated by checking the "last updated" date displayed at the top of this Notice.
                                     </li>
                                 </ol>
                            </div>
                        </Container>
                        <Footer />
                    </Container>
                    <CookieConsent />
                </Fragment>
            }
        </Fragment>
    )
}

export default PrivacyPolicy

import Title from '../../Shared/Title'
import Container from '../../Shared/Container'
import { LEGAL_TERMS_DATA } from '../../../data/legalTermsData'

import './cookies.styles.css'

const CookiesSection = ( { id } ) => {
    const { title, content } = LEGAL_TERMS_DATA.cookies

    return (
        <Container classes={ 'cookies' } flex flexColumn id={ id }>
            <Title size={ 'block-title' }>
                <h5>{ title }</h5>
            </Title>
            <Container classes={ 'cookies-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.aboutNotice.title }</h4>
                {
                    content.aboutNotice.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'cookies-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.aboutCookies.title }</h4>
                {
                    content.aboutCookies.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'cookies-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.cookiesExpiration.title }</h4>
                {
                    content.cookiesExpiration.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'cookies-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.cookiesUsage.title }</h4>
                {
                    content.cookiesUsage.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
                <Container classes={ 'cookies-block-table' } flex spaceBetween>
                    <Container classes={ 'cookies-block-table-block' } flex flexColumn>
                        <h4 className={ 'cookies-block-table-block-title' }>{ content.cookiesUsage.cookiesDataTable.typeOfCookies.title }</h4>
                        {
                            content.cookiesUsage.cookiesDataTable.typeOfCookies.content.map( ( text, index ) => (
                                <p key={ index }>{ text.text }</p>
                            ) )
                        }
                    </Container>
                    <Container classes={ 'cookies-block-table-block' } flex flexColumn>
                        <h4 className={ 'cookies-block-table-block-title' }>{ content.cookiesUsage.cookiesDataTable.cookiesProvider.title }</h4>
                        {
                            content.cookiesUsage.cookiesDataTable.cookiesProvider.content.map( ( text, index ) => (
                                <p key={ index }>{ text.text }</p>
                            ) )
                        }
                    </Container>
                    <Container classes={ 'cookies-block-table-block' } flex flexColumn>
                        <h4 className={ 'cookies-block-table-block-title' }>{ content.cookiesUsage.cookiesDataTable.cookiesDuration.title }</h4>
                        {
                            content.cookiesUsage.cookiesDataTable.cookiesDuration.content.map( ( text, index ) => (
                                <p key={ index }>{ text.text }</p>
                            ) )
                        }
                    </Container>
                    <Container classes={ 'cookies-block-table-block' } flex flexColumn>
                        <h4 className={ 'cookies-block-table-block-title' }>{ content.cookiesUsage.cookiesDataTable.cookiesCancelation.title }</h4>
                        {
                            content.cookiesUsage.cookiesDataTable.cookiesCancelation.content.map( ( text, index ) => (
                                <p key={ index }>{ text.text }</p>
                            ) )
                        }
                    </Container>
                </Container>
            </Container>
            <Container classes={ 'cookies-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.targetedAd.title }</h4>
                {
                    content.targetedAd.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'cookies-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.cookiesControl.title }</h4>
                {
                    content.cookiesControl.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'cookies-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.cookiesPast.title }</h4>
                {
                    content.cookiesPast.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'cookies-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.cookiesMore.title }</h4>
                {
                    content.cookiesMore.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'cookies-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.cookiesUpdatePolicy.title }</h4>
                {
                    content.cookiesUpdatePolicy.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
        </Container>
    )
}

export default CookiesSection

import { useState, useEffect, useRef, Fragment } from 'react';
import Header from '../components/Header/Header';
import Container from '../components/Shared/Container';
import Title from '../components/Shared/Title';
import Loader from '../components/Shared/Loader';
import Background from '../threejs/Background';
import Footer from '../components/Footer/Footer';
import Navigation from '../components/Navigation/Navigation';
import CookieConsent from '../components/CookieConsent/CookieConsent';

const CookiePolicy = () => {
    const [loading, setLoading] = useState(true);
    const [dynamicCookies, setDynamicCookies] = useState([]);
    const isMounted = useRef(true);

    useEffect(() => {
        // Loading simulation
        const loadTimer = setTimeout(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        }, 3000);

        // Fetch and parse cookies
        const fetchCookies = () => {
            const cookies = document.cookie ? document.cookie.split(';') : [];
            const cookieArray = cookies.map((cookieStr) => {
                const [name, value] = cookieStr.trim().split('=').map(decodeURIComponent);
                return { name, value: value || '' };
            });

            const cookieInfo = cookieArray.map((cookie) => {
                let purpose = 'Unknown';
                let lifespan = 'Session';

                switch (cookie.name) {
                    case '_ga':
                        purpose = 'Used by Google Analytics to distinguish users';
                        lifespan = '2 years';
                        break;
                    case '_gid':
                        purpose = 'Used by Google Analytics to distinguish users';
                        lifespan = '24 hours';
                        break;
                    case '_gat':
                        purpose = 'Used by Google Analytics to throttle request rate';
                        lifespan = 'A few seconds';
                        break;
                    case '__Secure-3PAPISID':
                    case '__Secure-3PSID':
                        purpose = 'Google Authentication';
                        lifespan = 'Session';
                        break;
                    case 'NID':
                        purpose = 'Google Maps cookie';
                        lifespan = '6 months';
                        break;
                    case 'CONSENT':
                        purpose = 'Google cookie for ad personalization settings';
                        lifespan = '20 years';
                        break;
                    default:
                        break;
                }

                return { name: cookie.name, purpose, lifespan };
            });

            setDynamicCookies(cookieInfo);
        };

        fetchCookies();

        return () => {
            isMounted.current = false;
            clearTimeout(loadTimer);
        };
    }, []);

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <Background />
                    <Navigation route={'cookie-policy'} />
                    <Header home={false} />
                    <Container classes={'home-screen-container'}>
                        <Container classes={'hero-section'} flex spaceBetween id={'top'}>
                            <Title size={'large is-visible'}>
                                <h1>Cookie</h1>
                                <h2>Policy</h2>
                            </Title>
                        </Container>
                        <Container classes={''} flex flexColumn id={''}>
                            <div className="content-section">
                                <div className="content-section-title">1. About this Notice</div>
                                <ol className="content-section-list">
                                    <li className="content-section-line">
                                        This Cookie Policy ("<em>Policy</em>") explains how we use cookies and similar technologies to recognize you when you visit our website at <a href="https://reeflect-glass.com">https://reeflect-glass.com</a> ("<em>Website</em>"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
                                    </li>
                                    <li className="content-section-line">
                                        Please take the time to read this Policy carefully. If you have any questions or comments, please contact us via <a href="mailto:info@reeflect-glass.com">info@reeflect-glass.com</a>.
                                    </li>
                                </ol>
                            </div>

                            <div className="content-section">
                                <div className="content-section-title">2. What are cookies?</div>
                                <ol className="content-section-list">
                                    <li className="content-section-line">
                                        Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.
                                    </li>
                                    <li className="content-section-line">
                                        Cookies have many different features, such as allowing you to navigate between pages efficiently, remembering your preferences, and generally improving the user experience. They can also help ensure that the advertisements you see online are more relevant to you and your interests.
                                    </li>
                                    <li className="content-section-line">
                                        Cookies set by the website owner (in this case, us) are called "first party cookies". Cookies set by parties other than the website owner are called "third party cookies". Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). The parties that set these third party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.
                                    </li>
                                </ol>
                            </div>

                            <div className="content-section">
                                <div className="content-section-title">3. How long do cookies last?</div>
                                <ol className="content-section-list">
                                    <li className="content-section-line">
                                        Cookies can remain on your computer or mobile device for different periods of time. Some cookies are 'session cookies', which means they only exist when your browser is open and are automatically deleted when you close your browser or quit the app. Other cookies are 'persistent cookies', meaning they survive after your browser or app is closed and can be used by websites or apps to recognize your computer when you re-open your browser or app later. The length of the cookies used on our website is explained in more detail in the table below.
                                    </li>
                                </ol>
                            </div>

                             <div className="content-section">
                                <div className="content-section-title">4. Why do we use cookies?</div>
                               <div className="content-section-paragraph">A cookie is a small piece of data (text file) that a website – when visited by a user – asks your browser to store on your device in order to remember information about you, such as your language preference or login information. Those cookies are set by us and called first-party cookies. We also use third-party cookies – which are cookies from a domain different than the domain of the website you are visiting – for our advertising and marketing efforts. More specifically, we use cookies and other tracking technologies for the following purposes:</div>

                                <div className="content-section-subtitle">Strictly Necessary Cookies</div>
                                <div className="content-section-paragraph">These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.</div>

                                <div className="content-section-subtitle">Performance Cookies</div>
                                <div className="content-section-paragraph">These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.</div>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Cookie Subgroup</th>
                                            <th>Cookies</th>
                                            <th>Cookies used</th>
                                            <th>Lifespan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><a href="https://reeflect-glass.com">reeflect-glass.com</a></td>
                                            <td><a href="https://cookiepedia.co.uk/cookies/_gid">_gid</a></td>
                                            <td>Used by Google Analytics to distinguish users</td>
                                            <td>24 hours</td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://reeflect-glass.com">reeflect-glass.com</a></td>
                                            <td><a href="https://cookiepedia.co.uk/cookies/_ga">_ga</a></td>
                                            <td>Used by Google Analytics to distinguish users</td>
                                            <td>2 years</td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://reeflect-glass.com">reeflect-glass.com</a></td>
                                            <td><a href="https://cookiepedia.co.uk/cookies/_gat">_gat</a></td>
                                            <td>Used by Google Analytics to throttle request rate</td>
                                            <td>few seconds</td>
                                        </tr>
                                        {
                                            dynamicCookies.map(
                                                (cookie, index) =>
                                                (
                                                    (cookie.name !== '_gid' && cookie.name !== '_ga' && cookie.name !== '_gat')
                                                    ?
                                                    <tr key={index}>
                                                        <td><a href="https://reeflect-glass.com">reeflect-glass.com</a></td>
                                                        <td>{cookie.name}</td>
                                                        <td>{cookie.purpose}</td>
                                                        <td>{cookie.lifespan}</td>
                                                    </tr>
                                                    :
                                                    null
                                                    )
                                                )
                                        }
                                    </tbody>
                                </table>

                                <div className="content-section-subtitle">Functional Cookies</div>
                                <div className="content-section-paragraph">These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.</div>

                                <div className="content-section-subtitle">Targeting Cookies</div>
                                <div className="content-section-paragraph">These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.</div>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Cookie Subgroup</th>
                                            <th>Cookies</th>
                                            <th>Cookies used</th>
                                            <th>Lifespan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><a href="https://www.google.com">google.com</a></td>
                                            <td>NID</td>
                                            <td>Third Party</td>
                                             <td>6 months</td>
                                        </tr>
                                         <tr>
                                            <td><a href="https://www.google.com">google.com</a></td>
                                            <td>CONSENT</td>
                                            <td>Third Party</td>
                                            <td>2 years</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                           <div className="content-section">
                                <div className="content-section-title">6. What about other tracking technologies, like web beacons?</div>
                                <ol className="content-section-list">
                                    <li className="content-section-line">
                                         Cookies are not the only way to recognise or track visitors to a website. We may use other, similar technologies from time to time, like web beacons (sometimes called "tracking pixels" or "clear gifs"). These are tiny graphics files that contain a unique identifier that enables us to recognise when someone has visited our Website or opened an e-mail that we have sent them. This allows us, for example, to monitor the traffic patterns of users from one page within our Website to another, to deliver or communicate with cookies, to understand whether you have come to our Website from an online advertisement displayed on a third-party website, to improve site performance, and to measure the success of e-mail marketing campaigns. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.
                                    </li>
                                </ol>
                            </div>
                            <div className="content-section">
                                <div className="content-section-title">7. What about targeted advertising?</div>
                                <div className="content-section-paragraph">Third parties may serve cookies on your computer or mobile device to serve advertising through our Website. These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness of advertisements. This can be accomplished by them using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you. The information collected through this process does not enable us or them to identify your name, contact details or other personally identifying details unless you choose to provide these.</div>
                            </div>

                            <div className="content-section">
                                <div className="content-section-title">8. How can I control cookies?</div>
                                <ol className="content-section-list">
                                    <li className="content-section-line">
                                        You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by clicking on the appropriate opt-out links provided in the "privacy settings" tab appearing on your screen. The opt-out preference is valid for 30 days. After this period you are able to revert your decision.
                                    </li>
                                    <li className="content-section-line">
                                         You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information.
                                    </li>
                                    <li className="content-section-line">
                                        In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please visit <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a> or <a href="http://www.youronlinechoices.com">http://www.youronlinechoices.com</a>.
                                    </li>
                                </ol>
                            </div>

                            <div className="content-section">
                                <div className="content-section-title">9. The cookies that were sent in the past</div>
                                <ol className="content-section-list">
                                    <li className="content-section-line">
                                        If you have disabled one or more cookies, we can always use information collected by these cookies before the deactivation. However, we cease to collect information via the opted-out cookie.
                                    </li>
                                </ol>
                            </div>

                            <div className="content-section">
                                <div className="content-section-title">10. More information about cookies</div>
                                <ol className="content-section-list">
                                    <li className="content-section-line">
                                         For more information about cookies, including explanation about how cookies are placed on your device, or how to manage and delete them, visit <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>.
                                    </li>
                                </ol>
                            </div>

                             <div className="content-section">
                                <div className="content-section-title">11. Updates to this Policy</div>
                                <ol className="content-section-list">
                                    <li className="content-section-line">
                                        We may update this Policy from time to time in response to changing legal, technical or business developments. When we update our Policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material changes of this Policy if and where this is required by applicable data protection laws.
                                    </li>
                                    <li className="content-section-line">
                                        You can see when this Policy was last updated by checking the "last updated" date displayed at the top of this Policy.
                                    </li>
                                </ol>
                            </div>
                        </Container>
                        <Footer />
                    </Container>
                    <CookieConsent />
                </Fragment>
            )}
        </Fragment>
    );
};

export default CookiePolicy;

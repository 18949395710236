import Title from '../../Shared/Title'
import Container from '../../Shared/Container'
import { LEGAL_TERMS_DATA } from '../../../data/legalTermsData'

import './privacy.styles.css'

const PrivacySection = ( { id } ) => {
    const { title, content } = LEGAL_TERMS_DATA.privacy

    return (
        <Container classes={ 'privacy' } flex flexColumn id={ id }>
            <Title size={ 'block-title' }>
                <h5>{ title }</h5>
            </Title>
            <Container classes={ 'privacy-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.aboutPolicy.title }</h4>
                {
                    content.aboutPolicy.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'privacy-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.personalData.title }</h4>
                <p>{ content.personalData.subtitle }</p>
            </Container>
            <Container classes={ 'privacy-block-table' } flex spaceBetween>
                <Container classes={ 'privacy-block-table-block' } flex flexColumn>
                    <h4 className={ 'privacy-block-table-block-title' }>{ content.personalData.personalDataTable.reason.title }</h4>
                    {
                        content.personalData.personalDataTable.reason.content.map( ( text, index ) => (
                            <p key={ index }>{ text.text }</p>
                        ) )
                    }
                </Container>
                <Container classes={ 'privacy-block-table-block' } flex flexColumn>
                    <h4 className={ 'privacy-block-table-block-title' }>{ content.personalData.personalDataTable.dataType.title }</h4>
                    {
                        content.personalData.personalDataTable.dataType.content.map( ( text, index ) => (
                            <p key={ index }>{ text.text }</p>
                        ) )
                    }
                </Container>
                <Container classes={ 'privacy-block-table-block' } flex flexColumn>
                    <h4 className={ 'privacy-block-table-block-title' }>{ content.personalData.personalDataTable.legalBasis.title }</h4>
                    {
                        content.personalData.personalDataTable.legalBasis.content.map( ( text, index ) => (
                            <p key={ index }>{ text.text }</p>
                        ) )
                    }
                </Container>
            </Container>
            <Container classes={ 'privacy-block' } flex flexColumn>
                {
                    content.personalData.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'privacy-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.sharePersonalData.title }</h4>
                {
                    content.sharePersonalData.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'privacy-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.protectPersonalData.title }</h4>
                {
                    content.protectPersonalData.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'privacy-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.dataProtectionRights.title }</h4>
                {
                    content.dataProtectionRights.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'privacy-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.otherWebsites.title }</h4>
                {
                    content.otherWebsites.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
            <Container classes={ 'privacy-block' } flex flexColumn>
                <h4 className={ 'title-h5' }>{ content.policyUpdates.title }</h4>
                {
                    content.policyUpdates.content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
        </Container>
    )
}

export default PrivacySection
